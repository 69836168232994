import { getDomElems } from '../../_shared-components/getDomElems';
import { FOOTER_ID } from '../util/selectors';
import Toast from './Toast';

const clickHandler = (e, popover) => {
  e.preventDefault();
  if (global.window.OneTrust) {
    global.window.OneTrust.ToggleInfoDisplay();
  } else {
    popover.toggle();
  }
};

const createFallBackExperience = (element) => new Toast(element);

/**
 * Initializes Privacy Choices button (which triggers a OneTrust modal).
 * @param {Object} selectors
 */
const init = (selectors) => {
  const footerElem = global.window.document.getElementById(FOOTER_ID);
  const privacySelectors = getDomElems(selectors, footerElem);
  if (privacySelectors === null) {
    return;
  }
  const { privacyElem } = privacySelectors;
  const popover = createFallBackExperience(privacyElem);
  privacyElem.addEventListener('click', (e) => clickHandler(e, popover));
};
export default init;
