import { PRIVACY_FALLBACK_ID } from '../util/selectors';

export default class Toast {
  constructor(element) {
    this.trigger = element;
    this.wrapper = element.parentElement;
    this.trigger = element;
    this.wrapper = element.parentElement;
    this.popover = global.window.document.createElement('div');
    const icon = global.window.document.createElement('div');
    icon.setAttribute('data-js', 'fallback-icon');
    const text = global.window.document.createElement('span');
    text.setAttribute('data-js', 'fallback-text');
    const button = global.window.document.createElement('button');
    button.setAttribute('data-js', 'fallback-button');
    this.popover.setAttribute('id', PRIVACY_FALLBACK_ID);
    this.popover.setAttribute('class', 'cdr-toast');
    icon.setAttribute('class', 'cdr-toast__icon');
    text.setAttribute('class', 'cdr-toast__message');
    button.setAttribute('class', 'cdr-toast__button');
    button.setAttribute('type', 'button');
    icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="cdr-icon"><path role="presentation" d="M12.447 3.11a1 1 0 01.447.448l8.002 15.995A1 1 0 0120.002 21H3.998a1 1 0 01-.894-1.447l8.002-15.995a1 1 0 011.341-.447zM12 17a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm.22-9.5h-.47a1 1 0 00-.966.913l-.004.116.191 6.5a1 1 0 00.886.965l.114.006h.058a1 1 0 00.99-.857l.01-.114.19-6.5.001-.029a1 1 0 00-1-1z"></path></svg>';
    text.innerHTML = '<strong>"Privacy Choices" may be unavailable.</strong>Please check your browser\'s privacy settings and third-party extensions and try again later.';
    button.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="cdr-icon"><path role="presentation" d="M13.406 12.006l3.297-3.296a1 1 0 10-1.414-1.414l-3.297 3.295-3.285-3.295A1 1 0 107.293 8.71l3.285 3.295-3.285 3.288a1 1 0 001.414 1.415l3.285-3.289 3.297 3.289a1 1 0 001.414-1.415l-3.297-3.287z"></path></svg>';
    this.popover.append(icon);
    this.popover.append(text);
    this.popover.append(button);

    // Utility methods
    this.handleWindowEvent = () => {
      if (this.isVisible) {
        this.show();
      }
    };

    this.handleDocumentEvent = (evt) => {
      if (this.isVisible && evt.target !== this.wrapper
                && evt.target !== this.trigger && evt.target !== this.popover) {
        this.popover.remove();
        global.document.querySelector('[data-js="footer-privacy-fallback"]').style.contentVisibility = 'auto';
      }
    };
  }

  get isVisible() {
    return global.window.document.body.contains(this.popover);
  }

  show() {
    global.window.document.addEventListener('click', this.handleDocumentEvent);
    global.window.addEventListener('resize', this.handleWindowEvent);
    this.wrapper.appendChild(this.popover);
    global.document.querySelector('[data-js="footer-privacy-fallback"]').style.contentVisibility = 'initial';
  }

  destroy() {
    const footerButton = global.window.document.getElementById('footer-privacy-choices');
    this.popover.remove();
    global.window.removeEventListener('click', this.handleDocumentEvent);
    global.window.removeEventListener('resize', this.handleWindowEvent);
    global.document.querySelector('[data-js="footer-privacy-fallback"]').style.contentVisibility = 'auto';
    footerButton.focus();
  }

  toggle() {
    if (this.isVisible) {
      this.destroy();
    } else {
      this.show();
    }
  }
}
