import '../../style/footer/footer.scss';
import '../util/global-shim';
import { main } from './main/main';

/**
 * This is the main js entry point.
 *
 * @param win Window object. Pass in to allow for window stubbing.
 * @returns {Window}
 */

main(window);
