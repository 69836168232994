/**
 * Use toggleButton when you want to inject a button to overlay a position relative container
 * that you want to click on to toggle a element open and closed.
 *
 * Inserts button that when clicked will add a toggled open class on the parent container and
 * the content container to be shown.
 *
 * Example:
 *
 * <div data-js='rei-toggle-container'>
 *   <div data-js='rei-toggle-container__header'>
 *     <span data-js='rei-toggle-container__header--text'>header</span>
 *   </div>
 *   <div data-js='rei-toggle-container__content' id='content1'></div>
 * </div>
 *
 * initToggleButton({
 *   document: win.document,
 * });
 *
 * After init:
 *
 * <div data-js='rei-toggle-container'>
 *   <div data-js='rei-toggle-container__header'>
 *     <span data-js='rei-toggle-container__header--text'>header</span>
 *     <button class="rei-toggle-button" aria-expanded="false" aria-controls="content1">
 *       <span class="rei-toggle-button-text">header</span>
 *     </button>
 *   </div>
 *   <div data-js='rei-toggle-container__content' id='content1'></div>
 * </div>
 *
 * After button clicked:
 *
 * <div data-js='rei-toggle-container' class='rei-toggled-open'>
 *   <div data-js='rei-toggle-container__header'>
 *     <span data-js='rei-toggle-container__header--text'>header</span>
 *     <button class="rei-toggle-button" aria-expanded="true" aria-controls="content1">
 *       <span class="rei-toggle-button-text">header</span>
 *     </button>
 *   </div>
 *   <div data-js='rei-toggle-container__content' id='content1' class='rei-toggled-visible'></div>
 * </div>
 *
 * NOTE: selectors and classes used can be overridden and can call initToggleButton multiple times
 *       with different config
 */

/**
 * Toggle open classes on elements
 * @param {HTMLButtonElement} button
 * @param {Object} config
 * @returns {Boolean} true is current open state is true
 */
const toggleElementsClass = (button, config) => {
  const {
    parentElementSelector,
    toggleElementSelector,
    toggleParentClass,
    toggleContentClass,
  } = config;
  const toggleElement = button.closest(parentElementSelector);
  toggleElement.querySelector(toggleElementSelector).classList.toggle(toggleContentClass);
  return toggleElement.classList.toggle(toggleParentClass);
};

/**
 * Updates the aria expanded state on a button
 * @param {HTMLButtonElement} button
 * @param {Boolean} isToggled
 */
const updateElementsAria = (button, isToggled) => {
  button.setAttribute('aria-expanded', isToggled);
};

/**
 * Gets the button's content elements id
 * @param {HTMLButtonElement} button
 * @param {Object} config
 * @returns {String} content element id
 */
const getToggleElementId = (button, config) => {
  const {
    parentElementSelector,
    toggleElementSelector,
  } = config;
  const parentElement = button.closest(parentElementSelector);
  const toggleElement = parentElement.querySelector(toggleElementSelector);
  return toggleElement.id;
};

/**
 * Handler when a toggle is to happen
 * @param {HTMLButtonElement} button
 * @param {Object} config
 */
const handleToggle = (button, config) => {
  const isToggled = toggleElementsClass(button, config);
  updateElementsAria(button, isToggled);
};

/**
 * Construct a text element for button label
 * @param {Object} config
 * @param {HTMLElement} element
 * @returns
 */
const buildToggleLabel = (config, element) => {
  const { document, toggleHeaderTextSelector, toggleButtonTextClass } = config;
  const { textContent } = element.querySelector(toggleHeaderTextSelector) || {};
  const label = document.createElement('span');
  label.classList.add(toggleButtonTextClass);
  label.textContent = textContent || '';
  return label;
};

/**
 * Constructs a toggle button and listen to events
 * @param {Object} results
 * @param {Object} config
 * @param {HTMLElement} element
 * @returns {HTMLButtonElement}
 */
const buildToggleButton = (results, config, element) => {
  const { document, toggleButtonClass } = config;
  const { buttons } = results;
  const button = document.createElement('button');
  button.classList.add(toggleButtonClass);
  button.appendChild(buildToggleLabel(config, element));
  button.addEventListener('click', (event) => {
    handleToggle(event.target, config);
  });
  buttons.push(button);
  return button;
};

/**
 * Combine selectors and query for all elements to add
 * toggle buttons to and store in results object
 * @param {Object} results
 * @param {Object} config
 */
const selectElements = (results, config) => {
  const { elements } = results;
  const { selectors } = config;
  const selector = selectors.join(', ');
  elements.push(...document.querySelectorAll(selector));
};

/**
 * Add toggle buttons to all elements returned from the
 * selector
 * @param {Object} results
 * @param {Object} config
 */
const appendToggleButtons = (results, config) => {
  const { elements } = results;
  elements.forEach((element) => {
    element.appendChild(buildToggleButton(results, config, element));
  });
};

/**
 * Init opened state based on the config toggle property
 * @param {Object} results
 * @param {Object} config
 */
const initToggleState = (results, config) => {
  const { buttons } = results;
  const { toggled } = config;
  if (toggled) {
    buttons.forEach((button) => handleToggle(button, config));
  }
};

/**
 * Add aria attributes to all injected buttons
 * @param {Object} results
 * @param {Object} config
 */
const addAccessibility = (results, config) => {
  const { buttons } = results;
  const { toggled } = config;
  buttons.forEach((button) => {
    button.setAttribute('aria-expanded', toggled);
    button.setAttribute('aria-controls', getToggleElementId(button, config));
  });
};

/**
 * Combine user config into the default config
 * @param {Object} config
 * @param {Object} userConfig
 */
const initConfig = (config, userConfig) => {
  Object.assign(config, userConfig);
};

/**
 * Returns a new state object containing config with selectors and class info
 * for creation of toggle button.
 * Results stores instances of the elements that the buttons are injects in as
 * well as the buttons injected.
 * @returns {Object}
 */
const getState = () => ({
  config: {
    document: undefined,
    selectors: ['[data-js="rei-toggle-container__header"]'],
    parentElementSelector: '[data-js="rei-toggle-container"]',
    toggleElementSelector: '[data-js="rei-toggle-container__content"]',
    // Container of text to inject into toggle button
    toggleHeaderTextSelector: '[data-js="rei-toggle-container__header--text"]',
    // Style class added to parentElementSelector
    toggleParentClass: 'rei-toggled-open',
    // Style class added to toggleElementSelector
    toggleContentClass: 'rei-toggled-visible',
    // Style class added to injected toggle button
    toggleButtonClass: 'rei-toggle-button',
    // Style class added to injected toggle button text element
    toggleButtonTextClass: 'rei-toggle-button-text',
    // Init toggle state
    toggled: false,
  },
  results: {
    elements: [],
    buttons: [],
  },
});

/**
 * Creates a function scoped state to allow for multiple instances with different
 * config to exist together.
 * @param {Object} userConfig
 * @returns {Object} state
 */
const initToggleButton = (userConfig) => {
  // set function scoped state
  const state = getState();
  const { config, results } = state;

  // Override defaults with user config
  initConfig(config, userConfig);
  // Select the elements that will get toggle buttons injected into
  selectElements(results, config);
  // Inject buttons into elements
  appendToggleButtons(results, config);
  // Set the toggle state for all buttons
  initToggleState(results, config);
  // Add accessible attributes
  addAccessibility(results, config);
  return state;
};

export default initToggleButton;
