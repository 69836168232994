/**
 * Footer
 */
import metrics from '@rei/metrics';
import initEmailSignup from '../components/email-signup/email-signup';
import { FOOTER_ID, emailSignupSelector } from '../util/selectors';
import initToggleButton from '../components/toggleButton';
import init from '../components/privacyPreference';

const FOOTER_SELECTOR = `#${FOOTER_ID}`;

export function app(win) {
  const {
    emailSignupContainer,
    emailSignupForm: signupForm,
    emailFormInput: emailInput,
    emailSignupFormInputGroup: inputGroup,
    emailSignupFormInputErrorBlock: errorMsg,
    emailSignupSuccess,
    emailSignupFailure,
  } = emailSignupSelector;
  initEmailSignup({
    config: {
      win,
      doc: win.document,
    },
    selectors: {
      emailSignupContainer,
      signupForm,
      emailInput,
      inputGroup,
      errorMsg,
      emailSignupSuccess,
      emailSignupFailure,
    },
  });
  initToggleButton({
    document: win.document,
  });
  metrics.init(
    {
      noview: true,
    },
    metrics.addClickListenersFromSelector(FOOTER_SELECTOR)
  );
  init({ privacyElem: '#footer-privacy-choices' });

  return win;
}

export function main(win = window) {
  if (win.document.readyState === 'loading') {
    // Loading hasn't finished yet
    win.document.addEventListener('DOMContentLoaded', app.bind(null, win));
  } else {
    // `DOMContentLoaded` has already fired
    app(win);
  }
}
