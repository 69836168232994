import { emailSignupSelector, emailSignupClasses } from '../../util/selectors';
import { getDomElems } from '../../../_shared-components/getDomElems';
import { analyticsCustomClickHandler } from '../../../_shared-components/navigationMetrics';

/**
 * Config object to override with init args
 * {
 *   win, // window, but can be mocked for testing
 *   doc, // window document, but can be mocked for testing
 * }
 */
let config = {
  win: null,
  doc: null,
};

/**
 * State caches selector results
 */
const state = {
  recaptchaLoaded: false,
  baseElement: null,
  emailSignupContainer: null,
  emailSignupSuccess: null,
  emailSignupFailure: null,
  signupForm: null,
  emailInput: null,
  inputGroup: null,
  errorMsg: null,
  footEmailRecapId: 'undefined',
};

/**
 * update error status
 */
const markAsError = () => {
  const { errorMsg, emailInput, inputGroup } = state;
  const { inputError } = emailSignupClasses;
  inputGroup.classList.add(inputError);
  errorMsg.style.display = 'block';
  emailInput.setAttribute('aria-invalid', 'true');
};

/**
* update error status
*/
const removeValidationError = () => {
  const { errorMsg, emailInput, inputGroup } = state;
  const { inputError } = emailSignupClasses;
  inputGroup.classList.remove(inputError);
  errorMsg.style.display = 'none';
  emailInput.setAttribute('aria-invalid', 'false');
};

/**
 * validate email format
 * @param event {object} current event
 * @param forceFocus {boolean} optional, default false. Make user stay in field?
 * @returns {boolean} if email field was validated or not
 */
const validateEmail = (event, forceFocus = false) => {
  const { emailInput } = state;
  const valid = event.target.checkValidity();
  event.preventDefault();
  if (valid) {
    removeValidationError();
    analyticsCustomClickHandler({
      linkName: 'footer_sign up for gearmail',
    });
  } else {
    markAsError();
    analyticsCustomClickHandler({
      linkName: 'footer_sign up for gearmail',
      errorCodes: 'invalid email address',
    });
    if (forceFocus) {
      emailInput.focus();
    }
  }
  return valid;
};

/**
 * Set recaptcha id  from recaptcha render
 */
const doneScriptLoaded = () => {
  const { win } = config;
  const { emailSignupCaptchaId } = emailSignupSelector;
  state.footEmailRecapId = win.grecaptcha.render(emailSignupCaptchaId, {
    sitekey: '6LdGCh8UAAAAAI78ftxgsyMBmlXMQMnuLSpNI8vI',
    callback: 'newsletterSignupCaptchaCallback',
    size: 'invisible',
  });
};

/**
 * Reset recaptcha and update id from recaptcha render
 */
const resetGrecaptcha = () => {
  const { win } = config;
  win.grecaptcha.reset();
  doneScriptLoaded();
};

/**
 * Adds class to style recaptcha element to display
 */
const renderGrecaptcha = () => {
  const { emailSignupContainer } = state;
  const { recaptchaLoaded } = emailSignupClasses;
  emailSignupContainer.classList.add(recaptchaLoaded);
};

/**
 * Build url to pull down recaptcha
 * @returns {string} url
 */
const buildRecaptchaUrl = () => {
  const baseUrl = 'https://www.google.com/recaptcha/api.js';
  const urlParams = 'onload=doneScriptLoaded&render=explicit';
  return `${baseUrl}?${urlParams}`;
};

/**
 * Convert form into url params string
 * @param {HTMLFormElement} form
 * @returns {string}
 */
const buildFormDataParamString = (form) => {
  let dataString = '';
  const formData = new FormData(form);
  formData.forEach((value, key) => {
    dataString += `${key}=${value}&`;
  });
  return dataString.length ? dataString.slice(0, -1) : '';
};

/**
 * Construct and send xhr request
 * @param {string} type
 * @param {string} url
 * @param {string} data
 * @param {() => void} successHandler
 * @param {() => void} errorHandler
 */
const xhrRequest = (type, url, data, successHandler, errorHandler) => {
  const xhr = new XMLHttpRequest();
  xhr.open(type, url, true);
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  xhr.addEventListener('load', () => {
    // NOTE: Added for issues with firefox error event not being called
    if (xhr.status === 200) {
      successHandler();
    } else {
      errorHandler();
    }
  });
  xhr.addEventListener('error', errorHandler);
  xhr.send(data);
};

/**
 * Handles updating ui after successfully signing up for emails
 */
const signupCompleteHandler = () => {
  const { emailSignupContainer, emailSignupSuccess } = state;
  const { waitSpinner, signedUp } = emailSignupClasses;
  emailSignupContainer.classList.remove(waitSpinner);
  emailSignupContainer.classList.add(signedUp);
  emailSignupSuccess?.focus();
  analyticsCustomClickHandler({
    linkName: 'footer_gearmail sign up successful',
    events: 'event10',
  });
};

/**
 * actions to take if email send failed (server down)
 */
const signupFailHandler = () => {
  const { emailSignupContainer, emailSignupFailure } = state;
  const { waitSpinner, failed } = emailSignupClasses;
  emailSignupContainer.classList.remove(waitSpinner);
  emailSignupContainer.classList.add(failed);
  emailSignupFailure?.focus();
  analyticsCustomClickHandler({
    linkName: 'footer_gearmail sign up successful',
    events: 'event10',
    errorCodes: 'server unavailable',
  });
};

/**
 * Sends email signup if captcha succeeds
 */
const newsletterSignupCaptchaCallback = () => {
  const { emailSignupContainer, signupForm } = state;
  const { waitSpinner } = emailSignupClasses;
  emailSignupContainer.classList.add(waitSpinner);
  const url = signupForm.getAttribute('action');
  const type = signupForm.getAttribute('method');
  const data = buildFormDataParamString(signupForm);
  xhrRequest(type, url, data, signupCompleteHandler, signupFailHandler);
};

/**
 * On submit validate email and recaptcha execute
 * @param {SubmitEvent} event
 * @returns {boolean}
 */
const handleOnSubmit = (event) => {
  const { win } = config;
  const { footEmailRecapId } = state;
  const valid = validateEmail(event, true);
  if (valid) {
    win.grecaptcha.execute(footEmailRecapId);
  }
  return valid;
};

/**
 * Adds script tag to pull in recaptcha
 */
const loadRecaptcha = () => {
  const { baseElement, recaptchaScript } = state;
  // Adding script tag to dom triggers the pull of the recaptcha script
  baseElement.appendChild(recaptchaScript);
  // NOTE: Can get rid of this is we can get xhr request for script to work
  // Set timeout if recaptcha fails to load by this time assuming failure
  setTimeout(() => {
    if (!state.recaptchaLoaded) {
      recaptchaScript.onload = () => {};
      signupFailHandler();
    }
  }, 2500);
};

/**
 * Load recaptcha if it isn't already loaded, else
 * reset recaptcha render
 */
const addRecaptcha = () => {
  const { win } = config;
  if (win.grecaptcha) {
    resetGrecaptcha();
    renderGrecaptcha();
  } else {
    loadRecaptcha();
  }
};

/**
 * Recaptcha script loaded handler, sets flag that script has been loaded
 * remove event used to lazy load script and render recaptcha
 */
const recaptchaScriptLoadHandler = () => {
  const { emailInput } = state;
  state.recaptchaLoaded = true;
  emailInput.removeEventListener('focus', addRecaptcha);
  renderGrecaptcha();
};

/**
 * Builds the script to load recaptcha
 * @returns {HTMLscriptElement}
 */
const createRecaptchaElement = () => {
  const { doc } = config;
  const { recaptchaScriptClass } = emailSignupSelector;
  const recaptchaScript = doc.createElement('script');
  recaptchaScript.classList.add(recaptchaScriptClass);
  recaptchaScript.setAttribute('src', buildRecaptchaUrl());
  recaptchaScript.setAttribute('type', 'text/javascript');
  recaptchaScript.onload = recaptchaScriptLoadHandler;
  return recaptchaScript;
};

/**
 * Add functions to window used by the recaptcha script
 */
const initRecaptchaHandlers = () => {
  const { win } = config;
  // put the callback for the recaptcha completion on the window
  win.newsletterSignupCaptchaCallback = newsletterSignupCaptchaCallback;
  win.doneScriptLoaded = doneScriptLoaded;
};

/**
 * Add listeners for email signup
 */
const initListeners = () => {
  const { emailInput, signupForm } = state;
  if (emailInput) emailInput.addEventListener('focus', addRecaptcha);
  if (signupForm) signupForm.addEventListener('submit', handleOnSubmit);
};

/**
 * Cache selector results and create elements to be added
 */
const initState = (elements) => {
  state.recaptchaScript = createRecaptchaElement();
  Object.assign(state, elements);
};

/**
 * Init email signup
 * @param {Object{win, doc}} args
 */
const initEmailSignup = (args) => {
  let functionState;
  // Selector for base element for email-signup
  const { emailSignupWrapper } = emailSignupSelector;
  // replace config defaults with user config
  config = { ...config, ...args.config };
  const { doc } = config;
  state.baseElement = doc.querySelector(emailSignupWrapper);
  const signUpElems = getDomElems(args.selectors, state.baseElement);
  // make sure selectors returned expected elements else exit
  if (signUpElems) {
    initState(signUpElems);
    initRecaptchaHandlers();
    initListeners();
    functionState = {
      state,
      validateEmail,
      signupCompleteHandler,
      signupFailHandler,
    };
  }
  // when functionState is undefined selectors failed to find elements
  return functionState;
};

export default initEmailSignup;
