const FOOTER_ID = 'page-footer';
const PRIVACY_FALLBACK_ID = 'fallback-onetrust';
const emailSignupSelector = {
  emailSignupWrapper: '[data-js="email-signup"]',
  emailSignupContainer: '[data-js="email-signup-container"]',
  emailSignupForm: '[data-js="email-signup-form"]',
  emailSignupFormInputGroup: '[data-js="email-signup-form-innards"]',
  emailSignupFormInputErrorBlock: '[data-js="email-signup-error-block"]',
  emailFormInput: '[data-js="email-signup-input"]',
  emailSignupSuccess: '[data-js="email-signup-success"]',
  emailSignupFailure: '[data-js="email-signup-failure"]',
  emailSignupCaptchaId: 'footerEmailRecaptcha',
  recaptchaScriptClass: 'recaptcha-script',
};

const emailSignupClasses = {
  waitSpinner: 'wait-spinner',
  signedUp: 'signed-up',
  failed: 'signed-up-fail',
  recaptchaLoaded: 'recaptcha-loaded',
  inputError: 'has-error',
};

const selectors = {
  emailSignupSelector,
  emailSignupClasses,
};

export {
  emailSignupSelector,
  emailSignupClasses,
  FOOTER_ID,
  PRIVACY_FALLBACK_ID,
};

export default selectors;
